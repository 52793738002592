/**
 * @author Kyles Light
 * @email kuilin@yangqianguan.com
 * @date 2018-07-14 11:07:15.916
 * @desc generated by yqg-cli@0.2.4
 */

import Resource, {api as UserApi} from 'moneycan/common/resource';

import http from 'ssr-common/vue/vue-http';

const UNKNOWN_ERROR = 'Unknown Error';

export default {
    name: 'Lender',

    title: 'Pemberi Pinjaman',

    data() {
        return {
            reqCount: 0
        };
    },

    computed: {
        isRequesting() {
            const vm = this;

            return vm.reqCount > 0;
        }
    },

    methods: {
        fetchUserInfo() {
            const {$router} = this;
            Resource.fetchUserInfo().then(
                () => {
                    $router.replace({name: 'LenderBorrowerList'});
                }
            ).catch(() => {
                $router.replace({name: 'LenderSignIn'});
            });
        },

        initHttpInterceptor() {
            const vm = this;
            const {$toast} = vm;

            // http request 拦截器
            http.interceptors.request.use(
                req => {
                    vm.reqCount += 1;

                    return req;
                },
                err => Promise.reject(err)
            );

            // http response 拦截器
            http.interceptors.response.use(
                res => {
                    vm.reqCount -= 1;

                    if (res.config.url && res.config.url.indexOf('http') === 0) {
                        return res;
                    }

                    const {status = {}} = res.data;
                    const {code, detail = UNKNOWN_ERROR} = status;

                    if (code === 0) { // api 正常返回
                        return res;
                    }

                    if (res.config.url !== UserApi.fetch) {
                        $toast.error(detail);
                    }

                    return Promise.reject(res);
                },
                error => Promise.reject(error.response.data)
            );
        }
    },

    mounted() {
        const vm = this;
        vm.initHttpInterceptor();
        vm.fetchUserInfo();
    }
};
